import React, { useEffect } from "react";
import "../../styling/DefaultView.css";
import { useNavigate } from "react-router-dom";
import { Box, Button, Link, Typography, Sheet } from "@mui/joy";
import LandingText from "../../components/joy/LandingText";
import { authGuard } from "../../helpers/AuthBarrier";


function DefaultView() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            if (await authGuard()) {
                navigate('/new-report');
            }
        }
        checkUser();
    }, [])

    const goSignIn = () => {
        navigate("/login");
    }
    
    return (<Box sx={{ display: "flex", flexGrow: 1, height: "90vh", justifyContent: "center"}}>
        <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", height: "100%", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', [theme.breakpoints.up('sm')]: {
                    width: '30%'
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%',
                    alignSelf: 'center',
                }, justifyContent: "center", gap: 2, alignItems: "center"
            })}>
            <Typography level="h1">Welcome!</Typography>
            <Button onClick={goSignIn} sx={{ fontSize: "1rem", padding: '10px', width: "85%"}}> Sign In </Button>
            <Link href="mailto:team@medxinsight.com"> Contact Us</Link>
        </Box>
    </Box>);
    }


export default DefaultView