import { Typography, Box } from '@mui/joy';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';



const StyledInput = styled('input')(({ theme }) => ({
    border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
    borderRadius: theme.radius.sm,
    minWidth: 0,
    width: '25px',
    height: '20px',
    padding: '10px',
    textAlign: 'center',
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    '&::placeholder': {
        opacity: 0.5,
        transition: '0.1s ease-out',
    },
    '&:focus': {
        outline: 'none',
        borderColor: theme.palette.primary.outlinedHoverBorder,
        boxShadow: `0 0 0 2px ${theme.palette.primary.outlinedHoverBorder}`,
    },
    '&:-webkit-autofill': {
        boxShadow: '0 0 0 1000px white inset',
    },
}));
 function IntegerInputAutoForm(props) {
    const { defaultValue, label, onChange, labelMinWidth, disabled } = props;
    const [ integerValue, setIntegerValue ] = useState(0);

    const handleChange = (event) => {
        const newValue = event.target.value;
        // Use a regular expression to allow only digits (positive integers)
        if (/^\d*$/.test(newValue)) {
            onChange(newValue);
            setIntegerValue(newValue)
        }
    }

    useEffect(() => {
        if (defaultValue) {
            setIntegerValue(defaultValue);
        }
    }, [defaultValue])


    return <Box sx={{display: "flex"}}>
        <Typography sx={{ minWidth: labelMinWidth, alignSelf: "center", paddingRight: 1}}> {label}: </Typography>
        <StyledInput
            disabled={disabled}
            value={integerValue}
            onChange={handleChange}
            inputProps={{
                inputMode: 'numeric', // On-screen keyboard should be numeric on mobile devices
                pattern: "[0-9]*",   // Ensures numeric input in supported browsers
            }}
        />
    </Box>

}


export default IntegerInputAutoForm