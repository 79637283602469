import { DialogActions, DialogContent, DialogTitle, Modal, ModalDialog, Button } from "@mui/joy";



export default function FormNotSaveableModal({ open, onClose, confirm }) {


    return <Modal open={open} onClose={onClose}>
        <ModalDialog variant="outlined">
            <DialogTitle> Form cannot be saved! </DialogTitle>
            <DialogContent> Not enough information has been compiled to create the cloud report. Don't worry, you can still navigate away or sign out and it will be saved locally. Continue starting a new form?</DialogContent>
            <DialogActions>
                <Button 
                sx={{ 
                    '&:hover': {
                        backgroundColor: "var(--light-gray)",
                    },
                 }}
                onClick={() => {
                    onClose()}}>
                    Keep current report locally
                </Button>
                <Button variant="outlined" sx={{ 
                    backgroundColor: "white",
                    '&:hover': {
                        backgroundColor: '#cfc9c8',
                    },
                 }} onClick={() => {
                    confirm()
                    onClose()}}>
                    Discard current report locally
                </Button>
            </DialogActions>
        </ModalDialog>
    </Modal>
}