import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CssVarsProvider, extendTheme } from '@mui/joy';
import { Provider } from 'react-redux';

import './App.css';

import MainLayout from './scaffolding/Layout';
import DefaultView from './views/no-auth/DefaultView';
import LoginView from './views/no-auth/LoginView';
import NewVisit from './views/auth/NewVisit';
import UploadReport from './views/auth/UploadVisit';
import PastReports from './views/auth/PastReports';
import AccountView from './views/auth/AccountView';
import LogOutComponent from './views/auth/SignOut';
import ProtectedRoute from './helpers/AuthBarrier';
import { AlertProvider } from './components/AlertContext';
import store from './redux/ConfigureStore';
import ForgotPasswordView from './views/no-auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPassword';
import PastReportsEditingPage from './views/auth/PastReportsEditingPage';
import PastReportsExpanded from './components/PastReportsExpanded';

const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          50: '#EDF5FD',
          100: '#ELE6FF',
          200: '#b2bbe3',
          300: '#a0b0f7',
          400: '#8194e6',
          500: '#7b92f5ff',
          600: '#5774f0',
          700: '#cbd1ecff',
          800: '#2a3041ff',
          900: '#2a3041ff',
          plainColor: '#7b92f5ff',
        },
      }
    },
    light: {
      palette: {
        primary: {
          50: '#EDF5FD',
          100: '#ELE6FF',
          200: '#b2bbe3',
          300: '#a0b0f7',
          400: '#8194e6',
          500: '#7b92f5ff',
          600: '#5774f0',
          700: '#cbd1ecff',
          800: '#2a3041ff',
          900: '#2a3041ff',
          plainColor: '#7b92f5ff',
        },
      }
    }
  },
  typography: {
    fontFamily: 'Questrial',
  },
  components: {
    JoyDialogTitle:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyButton:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
          backgroundColor: '#5774f0',
          padding: '10px',
          fontSize: '1rem',
          display: 'inline-block',
        }
      }
    },
    JoyTypography:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyInput:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoySelect:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
          height: "fit-content",
          padding: "10px",
          paddingLeft: '18px',
        }
      }
    },
    JoyOption:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyListItemButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Questrial',
          marginTop: '1px',
          marginBottom: '1px',
          paddingTop: '14px',
          paddingBottom: '14px',
          borderRadius: '12px',
        }
      }
    },
    JoyList: {
      styleOverrides: {
        root: {
          flexGrow: 0
        }
      }
    },
    JoyFormLabel:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyFormHelperText:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyLink:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color &&
            ownerState.color !== "inherit" && {
              color: `var(--joy-palette-primary-${ownerState.color})`
            }),
          margin: "var(--Icon-margin)",
          ...(ownerState.fontSize &&
            ownerState.fontSize !== "inherit" && {
              fontSize: `var(--Icon-fontSize, var(--joy-fontSize-${ownerState.fontSize}))`
            }),
          ...(ownerState.instanceFontSize &&
            ownerState.instanceFontSize !== "inherit" && {
              "--Icon-fontSize": `var(--joy-fontSize-${ownerState.instanceFontSize})`
            })
        })
      }
    },
    JoyAlert: {
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    }
  }
})

const router = createBrowserRouter([
  {path: '/', element: <MainLayout/>, children: [
    // Unprotected
    {index: true, element: <DefaultView/>},
    {path:'login', element: <LoginView/>},
    {path: 'forgot-password', element: <ForgotPasswordView />},
    // Protected
    {path: 'new-report', element: <ProtectedRoute> <NewVisit/> </ProtectedRoute>},
    {path: 'prerecorded-report', element: <ProtectedRoute> <UploadReport/> </ProtectedRoute>},
    {path: 'past-reports', element: <ProtectedRoute> <PastReports/> </ProtectedRoute>},
    {path: 'past-reports/:visitID', element: <ProtectedRoute> <PastReportsExpanded /> </ProtectedRoute>},
    {path: 'past-reports/editing/:visitID', element: <ProtectedRoute> <PastReportsEditingPage /> </ProtectedRoute>},
    {path: 'account', element: <ProtectedRoute> <AccountView/> </ProtectedRoute>},
    {path: 'sign-out', element: <ProtectedRoute> <LogOutComponent/> </ProtectedRoute>},
    {path: 'reset-password', element: <ResetPasswordView />}

  ]}
])

function App() {

  return (
    <AlertProvider>
      <CssVarsProvider theme={theme}>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
      </CssVarsProvider>
    </AlertProvider>
  );
}

export default App;
