import React, { useState, useEffect } from 'react';
import { Box, IconButton, Input, Sheet, Typography } from '@mui/joy';
import { convertJSONKeyToTitle } from '../../helpers/KeyConverters';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { isIsoDate } from '../../helpers/TimeConversionHelper';


export default function HeadersTable({ onChange, label, value, headers, orientation, disabled }) {
    const createEmptyRow = () => {
        return headers.reduce((acc, header) => {
            acc[header] = '';
            return acc;
        }, {});
    };

    const [rows, setRows] = useState([createEmptyRow()]);

    useEffect(() => {
        if (value && typeof value === 'object' && Object.keys(value).length > 0) {
            setRows([value]);
        }
        if (value && Array.isArray(value) && value.length > 0) {
            setRows(value);
        } else {
            setRows([createEmptyRow()]);
        }
    }, [value, headers]);

    const handleInputChange = (rowIndex, header, inputValue) => {
        const newRows = rows.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, [header]: inputValue };
            }
            return row;
        });
        setRows(newRows);
        updateParent(newRows);
    };

    const addRow = () => {
        const newRows = [...rows, createEmptyRow()];
        setRows(newRows);
        updateParent(newRows);
    };

    const removeRow = () => {
        if (rows.length > 1) {
            const newRows = rows.slice(0, -1);
            setRows(newRows);
            updateParent(newRows);
        }
    };

    const updateParent = (newRows) => {
        onChange(newRows);
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Typography level="body-lg" sx={{ display: 'flex', justifyContent: 'center' }}>{label || 'Table'}</Typography>
            <Sheet variant="outlined">
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} style={{ 
                                    padding: '8px', 
                                    borderBottom: '1px solid #ccc', 
                                    borderRight: '1px solid #ccc', 
                                    textAlign: 'center'
                                }}>
                                    <Typography level="body-sm" fontSize='16px' color='#32383E'>
                                        {convertJSONKeyToTitle(header)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {headers.map((header, colIndex) => {
                                    if (header.includes('time')) {
                                        if(isIsoDate(row[header])) {
                                            row[header] = row[header].split("T")[1].split(".")[0]
                                        }
                                    }
                                    return <td key={colIndex} style={{ 
                                        borderBottom: '1px solid #ccc', 
                                        borderRight: '1px solid #ccc'
                                    }}>
                                        <Input
                                            value={row[header] || ''}
                                            disabled={disabled}
                                            onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                                            size="sm"
                                            variant="plain"
                                            sx={{
                                                border: 'none',
                                                boxShadow: 'none',
                                            }}
                                        />
                                    </td>
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Sheet>
            <Box justifyContent='center' gap={2} display={orientation ? "none": "flex"}>
                <IconButton onClick={addRow} variant="outlined" sx={{ mt: 2 }} disabled={disabled}>
                    <Add />
                </IconButton>
                <IconButton onClick={removeRow} variant="outlined" sx={{ mt: 2 }} disabled={rows.length === 1 || disabled}>
                    <Remove />
                </IconButton>
            </Box>
        </Box>
    );
}