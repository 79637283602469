



export function convertJSONKeyToTitle(jsonKey) {
    return jsonKey
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function convertStringTitleToJSONKey(string) {
    const newString = string
    .toLowerCase()              // Convert the entire string to lowercase
    .replace(/\s+/g, '_');
    return newString;
}
