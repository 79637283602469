import { configureStore } from "@reduxjs/toolkit";
import saveFieldReducer from "./states/SaveFieldSlice";


export default configureStore({
    reducer: {
        saveField: saveFieldReducer
    }
})

