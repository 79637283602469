import React from "react";

import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { convertJSONKeyToTitle } from "./KeyConverters";


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
    },
    section: {
        margin: 10,
        padding: 10,
        fontSize: 12,
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'center',
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 100,
        width: 10,
        height: 10,
    },
});

const widthFormating = {
    'datetime': 50,
    'string': 50,
    'integer': 50,
    'image': 50,
    'textarea': {
        'small': 30,
        'medium': 50,
        'large': 100,
    },
}

function formatDateToString(date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  
    return `${year}/${month}/${day} ${hours}:${minutes}`;
}

function formateDateToTimeString(date) {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
}

// Handle render of every new line section as:
//     1. Fetch one instance of list at first.
//          a. Get the next iterations of the list UNTIL it laps over 100.
//          b. If it laps over 100, end current iteration of loop.
//          c. Set current iteration of instance to be the element that lapped over 100.
//          d. Restart at step 1 with that iteration.
//     2. At the end, return the componentsWithView.
const CallInformationSection = ({visitResult, formFields}) => {
    const componentsWithView = [];

    componentsWithView.push(<View style={{width: '100%', border: '1px solid black', marginTop: "10"}}/>)
    componentsWithView.push(<Text style={{fontSize: '16pt', fontWeight: 'bold'}}>{formFields['call_info']['label']}: </Text>)

    for (let key = 0; key < (formFields['call_info']['field_order'].length);) {
        let currentWidthView = 0;

        const toFlex = [];

        for(let getElements = key; getElements < formFields['call_info']['field_order'].length; getElements++) {
            // Check datetime
            if (formFields['call_info'][formFields['call_info']['field_order'][getElements]]['type'] === 'datetime') {
                if ((currentWidthView + widthFormating[formFields['call_info'][formFields['call_info']['field_order'][getElements]]['type']]) > 100) {
                    key = getElements;
                    break;
                } else {
                    currentWidthView += widthFormating[formFields['call_info'][formFields['call_info']['field_order'][getElements]]['type']];

                    const displayDate = new Date(`${visitResult['call_info'][formFields['call_info']['field_order'][getElements]]}`)
                    if (displayDate.toString() !== 'Invalid Date' && !isNaN(displayDate)) {
                        toFlex.push(
                            <View wrap={false} style={{width: '50%'}}>
                                <Text style={{paddingLeft: '1', fontSize: '10pt', textOverflow: 'false'}}><Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(formFields['call_info']['field_order'][getElements])}: </Text> {formatDateToString(displayDate)}</Text>
                            </View>)
                    } else {
                        toFlex.push(
                            <View wrap={false} style={{width: '50%'}}>
                                <Text style={{paddingLeft: '1', fontSize: '10pt', textOverflow: 'false'}}><Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(formFields['call_info']['field_order'][getElements])}: </Text> {visitResult['call_info'][formFields['call_info']['field_order'][getElements]]}</Text>
                            </View>
                        )
                    }
                }
            } else {
                if ((currentWidthView + widthFormating[formFields['call_info'][formFields['call_info']['field_order'][getElements]]['type']]) > 100) {
                    key = getElements;
                    break;
                } else {
                    currentWidthView += widthFormating[formFields['call_info'][formFields['call_info']['field_order'][getElements]]['type']];
                    toFlex.push(
                        <View wrap={false} style={{width: '50%'}}>
                            <Text style={{paddingLeft: '1', fontSize: '10pt', textOverflow: 'false'}}><Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(formFields['call_info']['field_order'][getElements])}: </Text> {visitResult['call_info'][formFields['call_info']['field_order'][getElements]]}</Text>
                        </View>
                    )
                }
            }

            // Bug right now? I think we keep adding the very last element of the list infinite times
            // If we reach the end of the list, meaning we get to formFields.length - 1, set key to be = getElements + 1 and continue
            if (getElements === formFields['call_info']['field_order'].length - 1) {
                key = getElements + 1;
            }
        }
        componentsWithView.push(
            <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {toFlex}
            </View>
        )

    }

    return componentsWithView;
}


const TreatmentInformationSection = ({visitResult, formFields}) => {
    const componentsWithView = [];

    componentsWithView.push(<View style={{width: '100%', border: '1px solid black', marginTop: "10"}}/>)
    componentsWithView.push(<Text style={{fontSize: '16pt', fontWeight: 'bold'}}>{formFields['treatment_info']['label']}: </Text>)

    for (let key = 0; key < (formFields['treatment_info']['field_order'].length);) {
        let currentWidthView = 0;

        const toFlex = [];

        for(let getElements = key; getElements < formFields['treatment_info']['field_order'].length; getElements++) {
            // Check textarea size
            if (formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['display'] === 'textarea') {
                if ((currentWidthView + widthFormating[formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['size']]) > 100) {
                    key = getElements;
                    break;
                } else {
                    currentWidthView += widthFormating[formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['size']];

                    if (formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['size'] === "small") {
                        toFlex.push(
                            <View wrap={false} style={{width: '30%', paddingVertical: 2, flexDirection: 'column'}}>
                                <Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(formFields['treatment_info']['field_order'][getElements])}: </Text>
                                <Text style={{fontSize: '10pt', padding: '1', margin: '1', border: '1pt dotted grey', textOverflow: 'false'}}>{visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]]}</Text>
                        </View>)
                    } else if (formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['size'] === "medium") {
                        toFlex.push(
                            <View wrap={false} style={{width: '50%', paddingVertical: 2, flexDirection: 'column'}}>
                                <Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(formFields['treatment_info']['field_order'][getElements])}: </Text>
                                <Text style={{fontSize: '10pt', padding: '1', margin: '1', border: '1pt dotted grey', textOverflow: 'false'}}>{visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]]}</Text>
                            </View>
                        )
                    } else {
                        toFlex.push(
                            <View wrap={false} style={{width: '100%', paddingVertical: 2, flexDirection: 'column'}}>
                                <Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(formFields['treatment_info']['field_order'][getElements])}: </Text>
                                <Text style={{fontSize: '10pt', padding: '1', margin: '1', border: '1pt dotted grey', textOverflow: 'false'}}>{visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]]}</Text>
                            </View>
                        )
                    }
                }
            } else {
                if ((currentWidthView + widthFormating[formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['type']]) > 100) {
                    key = getElements;
                    break;
                } else {
                    currentWidthView += widthFormating[formFields['treatment_info'][formFields['treatment_info']['field_order'][getElements]]['type']];

                    let holder = "";
                    if (Array.isArray(visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]])) {
                        holder = visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]].map((injury) => {
                            return injury + '\n'
                        })
                    } else if (visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]]) {
                        holder = visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]].replace(/, /g, '\n');
                    }
                    else {
                        holder = visitResult['treatment_info'][formFields['treatment_info']['field_order'][getElements]]
                    }
                    toFlex.push(
                        <View wrap={false} style={{width: '50%', minWidth: '35%'}}>
                            <Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{formFields['treatment_info']['field_order'][getElements]}: </Text>
                            <Text style={{fontSize: '10pt', padding: '1', margin: '1', border: '1pt dotted grey', textOverflow: 'false'}}>
                                {holder}
                            </Text>
                        </View>
                    )
                }
            }

            // Bug right now? I think we keep adding the very last element of the list infinite times
            // If we reach the end of the list, meaning we get to formFields.length - 1, set key to be = getElements + 1 and continue
            if (getElements === formFields['treatment_info']['field_order'].length - 1) {
                key = getElements + 1;
            }
        }
        componentsWithView.push(
            <View style={{flexDirection: 'row', flexWrap: 'wrap', paddingBottom: "15"}}>
                {toFlex}
            </View>
        )
    }

    return componentsWithView;

}


const HeaderTable = ({title, headers, rows, orientation}) => (
    <View style={{width: "100%"}}>
        {/* Title */}
        <Text style={{alignSelf:"center", fontSize: '13pt', fontWeight: 'heavy'}}> {title} </Text>
        {/* Headers */}
        <View style={{flexDirection: 'row'}}>
            {headers && headers.map((header, index) => {
                return <View style={{width: `${100 / headers.length}%`, border: "1px solid black"}}>
                    <Text style={{alignSelf: "center", fontSize: "10pt"}}>{convertJSONKeyToTitle(header)}</Text>
                </View>
            })}
        </View>
        {/* Rows */}
        
        { rows && !orientation ? rows.map((row, index) => {
            return <View style={{flexDirection: 'row', width: '100%'}}>
                {headers.map((header, index) => {
                    return <View style={{width: `${100 / headers.length}%`, border: "1px solid black"}}>
                        <Text style={{alignSelf: "center", fontSize: "10pt"}}>{row[header]}</Text>
                    </View>
                })}
            </View>
        }) : () => {
            return <View style={{flexDirection: 'row', width: '100%'}}>
                {Object.keys(rows).map((key, index) => {
                    return <View style={{width: `${100 / headers.length}%`, border: "1px solid black"}}>
                        <Text style={{alignSelf: "center", fontSize: "10pt"}}>{rows[key]}</Text>
                    </View>
                })}
            </View>
        }}

    </View>
)


const vitalsHeaders = [
    {key: "vitals_time", columns: 1, label: "Time"},
    {key: "vitals_spo2", columns: 2, label: "SpO2"},
    {key: "vitals_temp", columns: 1, label: "Temp"},
    {key: "vitals_bgl", columns: 1, label: "BGL"},
    {key: "vitals_resp", columns: 1, label: "Resp"},
    {key: "vitals_pulse", columns: 1, label: "Manual Pulse"},
    {key: "vitals_bp", columns: 2, label: "BP"},
    {key: "vitals_pupils", columns: 1, label: "Pupils"},
    {key: "vitals_skin", columns: 1, label: "Skin"},
]
const VitalsTable = ({ rows }) => {
    return <View style={{width: "100%"}}>
        {/* Title */}
        <Text style={{alignSelf:"center", fontSize: '13pt', fontWeight: 'heavy'}}> Vitals: </Text>
        {/* Headers */}
        <View style={{flexDirection: 'row'}}>
            {vitalsHeaders.map((header, index) => {
                return <View style={{width: `${header['columns'] * 8}%`, border: "1px solid black"}}>
                    <Text style={{alignSelf: "center", fontSize: "10pt"}}>{header['label']}</Text>
                </View>
            })}
        </View>
        {/* Rows, each row is a dictionary*/}
        {rows.map((row, index) => {
            return <View style={{flexDirection: 'row', width: '100%'}}>
                {vitalsHeaders.map((header, index) => {
                    if (header['key'] === "vitals_time") {
                        const displayDate = new Date(`${row[header['key']]}`)
                        if (displayDate.toString() !== 'Invalid Date' && !isNaN(displayDate)) {
                            return <View style={{width: `${header['columns'] * 8}%`, border: "1px solid black"}}>
                                <Text style={{alignSelf: "center", fontSize: "10pt"}}>{formateDateToTimeString(displayDate)}</Text>
                            </View>
                        } else {
                            return <View style={{width: `${header['columns'] * 8}%`, border: "1px solid black"}}>
                                <Text style={{alignSelf: "center", fontSize: "10pt"}}>{row[header['key']]}</Text>
                            </View>
                        }
                    }
                    else if (Array.isArray(row[header['key']]) && header['columns'] === 2) {
                        return <View style={{flexDirection: "row", width: `${header['columns'] * 8}%`}}>
                            <View style={{width: '50%', border: "1px solid black"}}>
                                <Text style={{alignSelf: "center", fontSize: "10pt"}}> {row[header['key']][0]} </Text>
                            </View>
                            <View style={{width: '50%', border: "1px solid black"}}>
                                <Text style={{alignSelf: "center", fontSize: "10pt"}}> {row[header['key']][1]} </Text>
                            </View>
                        </View>
                    }
                    else {
                        return <View style={{width: `${header['columns'] * 8}%`, border: "1px solid black"}}>
                            <Text style={{alignSelf: "center", fontSize: "10pt"}}>{row[header['key']]}</Text>
                        </View>
                    }
                })}
            </View> 
        })}

    </View>
}


// Create the PDF Document component
const DocumentGenerator = ({organizationPNG, visitResult, formFields}) => {
    return (<Document>
      <Page size="A4" style={styles.page}>
        <View style={{flexDirection: "row", fontSize: 12}}>
            {/* TODO: Figure out the base64 image rendering?? something with a CORS issue*/}
            <Image style={{width: "70%", maxHeight: "4in"}} src="/assets/mcmaster_logo.png"/>
            <View style={{width:"30%"}}>
                {Object.keys(visitResult['details']).map(((key, index) => {
                    return <Text style={{fontSize: '12pt', alignSelf: "center"}}>
                            {key}: {visitResult['details'][key]}
                        </Text>
                }))}
            </View>
        </View>
        <View style={styles.section}>
            <Text style={styles.title}>{`EFRT Report for ${visitResult['patient_info']['first_name']} ${visitResult['patient_info']['last_name']}`}</Text>
            <View style={{width: '100%', border: '1px solid black'}}/>
            <Text style={{fontSize: '16pt', fontWeight: 'bold'}}>Patient Information: </Text>
            <View style={{flexDirection: "row", width: '100%', flexWrap: "wrap"}}>
                {Object.keys(visitResult['patient_info']).map((key, index) => {
                    return <View style={{maxWidth: '60%', minWidth: '35%'}}>
                        <Text style={{paddingLeft: '1', fontSize: '10pt', textOverflow: 'false'}}><Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(key)}: </Text> {visitResult['patient_info'][key]}</Text>
                    </View>
                })}
            </View>

            <CallInformationSection organizationPNG={organizationPNG} visitResult={visitResult} formFields={formFields}/>

            <TreatmentInformationSection organizationPNG={organizationPNG} visitResult={visitResult} formFields={formFields} />

            <View style={{width: '100%', border: '1px solid black'}}/>
            <Text style={{fontSize: '16pt', fontWeight: 'bold'}}>{formFields['vitals_info']['label']}: </Text>
            <View style={{flexDirection: "row", width: '100%', flexWrap: "wrap"}}>
                {(formFields['vitals_info']['field_order']).map((key) => {
                    if (formFields['vitals_info'][key]['display'] === 'table' && 'headers' in formFields['vitals_info'][key]) {
                        return <HeaderTable title={convertJSONKeyToTitle(key)} headers={formFields['vitals_info'][key]['headers']} rows={visitResult?.['vitals_info']?.[key] ?? []} orientation={formFields['vitals_info'][key]['orientation'] === 'horizontal'} />
                    } else if (formFields['vitals_info'][key]['display'] === 'table') {
                        return <VitalsTable rows={visitResult?.['vitals_info']?.[key] ?? []}/>
                    } else {
                        return <View style={{width: '50%'}}>
                            <Text style={{paddingLeft: '1', fontSize: '10pt', textOverflow: 'false'}}><Text style={{fontSize: '12pt', fontWeight: 'semibold'}}>{convertJSONKeyToTitle(key)}: </Text> {visitResult['vitals_info'][key]}</Text>
                        </View>
                    }
                })}
            </View>

            <View style={{height: "10%"}}></View>
            <Text style={{fontSize: '12pt'}}> Reviewed by: </Text>
            <View style={{width: '60%', flexDirection: "row"}}>
                <Text style={{fontSize: '12pt', paddingVertical: "5"}}> Printed Name:</Text>
                <View style={{width: '70%', borderBottom: '1px solid black'}} />
            </View>
            <View style={{width: '60%', flexDirection: "row"}}>
                <Text style={{fontSize: '12pt', paddingVertical: "5"}}> Signature:</Text>
                <View style={{width: '70%', borderBottom: '1px solid black'}} />
            </View>

        </View>
      </Page>
    </Document>)
    };
  
  export default DocumentGenerator;