import { Button, Modal, Typography, Sheet, Box } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
import '../styling/ComponentStyling.css';


function NavBlocker(props) {
    let dirty = props.dirty;
    let blocker = useBlocker(dirty);

    let [ open, setOpenModal ] = useState(true);

    const openModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        blocker.reset?.();
        setOpenModal(false);
    }

    const handleCloseAndContinueModal = () => {
        blocker.proceed?.();
        setOpenModal(false);
    }

    useEffect(() => {
        if (blocker.state==="blocked") {
            setOpenModal(true);
        }
        if (blocker.state === "blocked" && dirty === false) {
            blocker.reset();
        }

        return() => {
        }
    }, [blocker, dirty])

    return (
        <>
        {blocker ? <ConfirmNavigation blocker={blocker} open={open} handleCloseModal={handleCloseModal} openModal={openModal} handleCloseAndContinueModal={handleCloseAndContinueModal}/> : null}
        </>
    )
}

function ConfirmNavigation ({ blocker, open, handleCloseModal, handleCloseAndContinueModal }) {
    if (blocker.state === "blocked") {
        return (
            <Modal open={open} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Sheet variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5
                    }}>
                    <Typography level="h3"> Unsaved Changes </Typography>
                    <Typography> Are you sure you want to navigate away? </Typography>
                    <Box display="flex" justifyContent="space-evenly">
                        <Button 
                            onClick={handleCloseModal}
                            sx={{
                                fontWeight: "normal", 
                                color: "white",
                                width: "7rem", 
                                '&:hover': { backgroundColor: 'var(--main-blue-hover)', color: 'white'}, 
                                '&:active': {backgroundColor: 'var(--light-blue-button)'}
                            }}
                        > 
                            CANCEL
                        </Button>
                        <Button 
                            variant="plain" 
                            onClick={handleCloseAndContinueModal}
                            sx={{
                                fontWeight: "normal", 
                                color: "black", 
                                backgroundColor: "white", 
                                border: "2px solid #f1f3f4",
                                width: "7rem"
                            }}
                        > 
                            CONTINUE 
                        </Button>
                    </Box>
                </Sheet>
            </Modal>
          );
    }
}

export default NavBlocker;