export function convertTimeString(timeString) {
    const localTimeStamp =  new Date(timeString.slice(0, 19) + 'Z');

    // Convert to AM/PM
    let hoursTime, ampm;
    if (localTimeStamp.getHours() > 12) {
        hoursTime = `${localTimeStamp.getHours() - 12}`
        ampm = 'PM'
    } else if (localTimeStamp.getHours() === 12) {
        hoursTime = `${localTimeStamp.getHours()}`
        ampm = 'PM'
    } else {
        hoursTime = `${localTimeStamp.getHours()}`
        ampm = 'AM'
    }
    // Format given time string to readable date Object
    return `${localTimeStamp.getFullYear()}/${localTimeStamp.getMonth() + 1}/${localTimeStamp.getDate()}, ${hoursTime}:${String(localTimeStamp.getMinutes()).padStart(2, "0")} ${ampm}`
}

// Keep it in UTC format
export function convertDateString(timeString) {
    const timeStamp = new Date(timeString);


    return timeStamp.getFullYear() + '/' + 
                     String(timeStamp.getMonth() + 1).padStart(2, '0') + '/' + 
                     String(timeStamp.getDate()).padStart(2, '0');
}

export function isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str); 
    return !isNaN(d.getTime()) && d.toISOString()===str; // valid date 
  }

export const convertTime = (time) => {
    const utcDate = new Date(`${time}Z`);
    const date = new Date(utcDate.getTime());
    // Extracting the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Extracting the time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Formatting the date and time
    const formattedDate = `${year}/${month}/${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    // Combining date and time
    const dateTimeString = `${formattedDate} ${formattedTime}`;

    return dateTimeString;
}

export function calculateAndConvertTime(interval) {
    const currentTime = new Date();

    switch(interval) {
        case ('current'): {
            const currentUTCMonthFormatted = String(currentTime.getUTCMonth() + 1).padStart(2, '0')
            const currentUTCDayFormatted = String(currentTime.getUTCDate()).padStart(2, '0')
            const currentUTCHoursFormatted = String(currentTime.getUTCHours()).padStart(2, '0')
            const currentUTCMinutesFormatted = String(currentTime.getUTCMinutes()).padStart(2, '0')
            const currentUTCSecondsFormatted = String(currentTime.getUTCSeconds()).padStart(2, '0')
            return `${currentTime.getUTCFullYear()}-${currentUTCMonthFormatted}-${currentUTCDayFormatted}T${currentUTCHoursFormatted}:${currentUTCMinutesFormatted}:${currentUTCSecondsFormatted}`
        } case ('24 hours ago'): {
            // Support retrieval of notes from the last 7 days
            const pastTime = new Date(currentTime.getTime() - (24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case ('last 7 days'): {
            // Support retrieval of notes from the last 7 days
            const pastTime = new Date(currentTime.getTime() - (7 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case('1 month'): {
            // Support retrieval of notes from the last month
            const pastTime = new Date(currentTime.getTime() - (30 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case ('3 months'): {
            const pastTime = new Date(currentTime.getTime() - (30* 3 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case ('1 year'): {
            const pastTime = new Date(currentTime.getTime() - (30* 12 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } default: {
            return 'Implement it later'
        }
    }
}

export function calculateTimeDifference(futureDate) {
    const now = new Date();
    const future = new Date(futureDate.slice(0, 19) + 'Z');
    const diffMs = future - now; 
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); 
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    
    return {
        hours: diffHrs,
        minutes: diffMins
    }
}

export function convertToUTCDate(dateString) {
    // Split the input date string by the '-' delimiter
    const [year, month, day] = dateString.split('-');
  
    // Create a new Date object in UTC format
    const utcDate = new Date(Date.UTC(year, month - 1, day));
  
    // Format the date as yyyy-mm-dd hh:mm:ss
    const formattedDate = utcDate.toISOString()
                                 .replace('T', ' ')
                                 .split('.')[0]; // Remove milliseconds
  
    return formattedDate;
}

export function retrieveDateStringFromISO(isoString) {
    return isoString.split("T")[0]
}

export function retrieveTimeStringFromISO(isoString) {
    return isoString.split("T")[1]
}