import * as React from 'react';
import { FormControl, FormLabel, Radio, RadioGroup, } from '@mui/joy';
import { convertJSONKeyToTitle } from '../../helpers/KeyConverters';

function RadioButtonAutoForm(props) {
    const { label, labelMinWidth, values, onChange, initialValue, disabled} = props;

    return (
        <FormControl orientation="horizontal">
            <FormLabel sx={{minWidth: labelMinWidth, fontSize: "1rem"}}>{label}:</FormLabel>
            <RadioGroup orientation="horizontal" defaultValue={initialValue} disabled={disabled}>
                {values.map((value, index) => (
                    <Radio key={index} value={value} label={convertJSONKeyToTitle(value)} onChange={onChange}/>
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioButtonAutoForm;


