import { ButtonGroup, DialogContent, DialogTitle, Modal, ModalDialog, Button, Box, Typography, Table, CircularProgress } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";
import { deleteAttachmentFromVisit, getVisitAttachmentFile, getVisitAttachments, uploadFileToVisit } from "../../helpers/VisitAttachmentsRouter";
import { Upload } from "@mui/icons-material";
import { InsertDriveFile, Audiotrack, Videocam, Image } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { AlertContext } from "../AlertContext";
import { convertTime } from "../../helpers/TimeConversionHelper";

const FileIcon = ({ fileName }) => {
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();

    // Check file type based on extension
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension)) {
      return <Image sx={{ fontSize: 40, color: '#1E88E5' }} />;
    } else if (['mp3', 'wav', 'aac', 'flac', 'ogg', 'm4a'].includes(extension)) {
      return <Audiotrack sx={{ fontSize: 40, color: '#8E24AA' }} />;
    } else if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'].includes(extension)) {
      return <Videocam sx={{ fontSize: 40, color: '#D32F2F' }} />;
    } else if (['txt', 'pdf', 'doc', 'docx', 'odt', 'rtf'].includes(extension)) {
      return <InsertDriveFile sx={{ fontSize: 40, color: '#43A047' }} />;
    } else {
      return <InsertDriveFile sx={{ fontSize: 40, color: '#757575' }} />; // Default file icon
    }
  };

  return (
    <div>
      {getFileIcon(fileName)}
    </div>
  );
};


function CreateData({file, visitID, getAndSet}) {
    const { addAlert } = useContext(AlertContext);

    const downloadFile = async () => {
        try {
            const attachmentWithURI = await getVisitAttachmentFile(visitID, file.id);
            
            // Fetch the file as a blob
            const response = await fetch(attachmentWithURI.presigned_get_url);
            const blob = await response.blob();
            
            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);
            
            // Create a temporary anchor element
            const tempLink = document.createElement('a');
            tempLink.href = url;
          
            // Set the file name using the download attribute
            tempLink.download = attachmentWithURI.file_name || 'default-file-name.ext'; // Fallback filename
          
            // Append the link to the body
            document.body.appendChild(tempLink);
          
            // Programmatically click the link to trigger the download
            tempLink.click();
          
            // Clean up by removing the element and revoking the blob URL
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(url); // Revoke the blob URL after download
        } catch (error) {
            addAlert("Couldn't download file", "danger")
            console.log(error)
        }
    };

    const deleteFile = async () => {
        try {
            await deleteAttachmentFromVisit(visitID, file.id);
            getAndSet();
        } catch(error) {
            addAlert("Couldn't delete file", "danger")
            console.log(error);
        }
    }

    return <tr key={file.file_name}>
        <td>
            <FileIcon fileName={file.file_name}/>
        </td>
        <td>
            {file.file_name}
        </td>
        <td>
            {convertTime(file.created_at)}
        </td>
        <td>
            <Box sx={{display: "flex", gap: 2, px: 2}}>
                <Button
                    startDecorator={<DownloadIcon />}
                    onClick={() => {downloadFile()}}
                    variant="plain"
                    sx={{
                        display: "flex",
                        color: "black",
                        backgroundColor: "white",
                        '&:hover': {
                            backgroundColor: "var(--light-gray)",
                        },
                    }}>
                    Download
                </Button>
                <Button
                    startDecorator={<DeleteOutlineIcon />}
                    onClick={() => {deleteFile()}}
                    sx={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "red",
                        '&:hover': {
                            backgroundColor: "var(--red-button-hover)",
                        },
                    }}
                >
                    Delete
                </Button>
            </Box>
        </td>
    </tr>
  }


function UploadAdditionalDocsModal({ open, setClose, visitID}) {
    const { addAlert } = useContext(AlertContext);
    const [ files, setFiles ] = useState(null);
    const [ view, setView ] = useState('attachments');

    const [dragging, setDragging] = useState(false);
    const [file, setFile] = useState(null);
    const [ uploading, setUploading ] = useState(false);
  
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      const uploadedFile = e.dataTransfer.files[0];
      setFile(uploadedFile);
      // Handle file upload logic here
    };
  
    const handleFileSelect = (e) => {
      const uploadedFile = e.target.files[0];
      setFile(uploadedFile);
      // Handle file upload logic here
    };
  

    const getAndSetFiles = async () => {
        try {
            const retrievedFiles = await getVisitAttachments(visitID);
            setFiles(retrievedFiles);
        } catch(error) {
            console.log(error);
        }
    }

    const uploadFile = async () => {
        try {
            setUploading(true);
            await uploadFileToVisit(visitID, file, file.name);
            setFile(null);
            addAlert("File uploaded!", 'success');
            await getAndSetFiles();
            setUploading(false);
        } catch (error) {
            console.log(error);
            addAlert("Couldn't upload file. Check that the report is in a draft status", "danger");
        }
    }

    useEffect(() => {
        getAndSetFiles();
    }, [])

    return <Modal open={open} onClose={setClose}>
        <ModalDialog>
            <DialogTitle> Upload Files </DialogTitle>
            <DialogContent>
                <ButtonGroup>
                    <Button
                        variant="plain"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "1px",
                            borderBottom: (view === "attachments" ? "3px solid black" : "none"),
                            '&:hover': {
                                backgroundColor: "var(--light-gray)",
                            },
                        }}
                        onClick={() => {setView("attachments")}}
                    >
                        View Attachments
                    </Button>
                    <Button
                        variant="plain"
                        sx={{
                        backgroundColor: "white",
                        borderRadius: "1px",
                        borderBottom: (view === "upload" ? "3px solid black" : "none"),
                        '&:hover': {
                            backgroundColor: "var(--light-gray)",
                        },
                        }}
                        onClick={() => {setView("upload")}}
                    >
                        Upload Attachments
                    </Button>
                </ButtonGroup>
                {view === 'attachments' && 
                <Table
                    borderAxis="bothBetween"
                >
                    <thead>
                        <tr>
                            <th
                                style={{width: 50}}
                            ></th>
                            <th>File Name</th>
                            <th>Date Uploaded</th>
                            <th
                                style={{width: 250}}
                            >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files && files.map((file, index) => {
                            return <CreateData file={file} visitID={visitID} key={file.uri} getAndSet={getAndSetFiles}/>
                        })}
                    </tbody>
                </Table>}
                {view === 'upload' && 
                <Box>
                    <Box
                    sx={{
                        border: '2px dashed #D1D5DB',
                        padding: '20px',
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: dragging ? '#f0f9ff' : '#fff',
                        transition: 'background-color 0.3s',
                        textAlign: 'center',
                        width: '400px',
                        height: '200px',
                        cursor: 'pointer',
                    }}
                    onDragEnter={handleDragEnter}
                    onDragOver={(e) => e.preventDefault()}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    >
                        <Upload sx={{ fontSize: 50, color: '#1E40AF' }} />
                        <Typography sx={{ marginTop: '10px', fontWeight: 'bold', color: '#1E293B' }}>
                            Drag and Drop file here or{' '}
                            <Button variant="plain" component="label" sx={{backgroundColor: "white"}}>
                            Choose file
                            <input type="file" hidden onChange={handleFileSelect} />
                            </Button>
                        </Typography>
                        {file && (
                            <Typography sx={{ marginTop: '10px', color: '#6B7280' }}>
                            {file.name} selected
                            </Typography>
                        )}
                        {uploading && <CircularProgress />}
                    </Box>
                    <Box sx={{ display: "flex", pt: 2 }}>
                        <Button startDecorator={<UploadIcon />} sx={{ display: "flex", ml: "auto", mr: "auto" }} onClick={() => uploadFile()} disabled={!file || uploading}>
                            Upload
                        </Button>
                    </Box>
                </Box>
                }
            </DialogContent>
        </ModalDialog>
    </Modal>
}

export default UploadAdditionalDocsModal;