import { Box } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { authGuard } from "../helpers/AuthBarrier";
import NavigationBar from "./Navigation";



function MainLayout() {
    const [navVisible, setNavVisible] = useState(false);

    const verifyAuthState = async () => {
        setNavVisible(await authGuard());
    }

    // Check if there actually is a token;
    const handleStorageToken = (event) => {
        if (event.detail.key === 'token') {
            verifyAuthState();
        }
    }


    useEffect(() => {
        window.addEventListener('localStorageChange', handleStorageToken);
        // Fetch available nav for first render
        verifyAuthState();

        return () => {
            window.removeEventListener('localStorageChange', handleStorageToken);
        }
    }, [])

    return (
        <Box sx={{ display: 'flex', width: "100%", height: "100%"}}>
            {navVisible && <NavigationBar/>}
            <Box style={{height: '100%', width: "100%", flex: 1}}>
                <Outlet/>
            </Box>
        </Box>
    )
}

export default MainLayout;