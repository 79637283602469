import React, { useEffect, useState } from "react";
import { getUserID } from "../../helpers/AuthBarrier";
import { convertTimeString } from "../../helpers/TimeConversionHelper";
import "../../styling/AccountAndSettingsView.css";
import "../../styling/LoginView.css";
import { useNavigate } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { axiosInstance } from "../../helpers/AxiosConfiguration";
import { CircularProgress, Button, Input, Tooltip, Typography, Box, } from "@mui/joy";

function AccountView() {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ type, setType ] = useState(null);
    const [ name, setName ] = useState(null);
    const [ jobTitle, setJobTitle ] = useState(null);
    const [ email, setEmail] = useState(null);
    const [ phoneNumber, setPhoneNumber ] = useState(null);
    const [ createdAt, setCreatedAt ] = useState("");
    const [ updatedAt, setUpdatedAt ] = useState('');

    const goChangePassword = () => {
        navigate("/reset-password");
    }

    async function getAccountDetails() {
        await axiosInstance.get(`/api/v1/user`).then( response => {
            const responseBody = response.data;
            setType(responseBody['type']);
            setName(responseBody['full_name']);
            setEmail(responseBody['email']);
            setJobTitle(responseBody['job_title']);
            setPhoneNumber(responseBody['phone_number']);
            setCreatedAt(convertTimeString(responseBody['created_at']));
            setUpdatedAt(convertTimeString(responseBody['updated_at']));
            setLoading(false);
        }).catch( error => {
            console.log("Error")
        })
    }

    useEffect( () => {
        getAccountDetails();
    }) 

    return (
        <Box>
            {loading && (<CircularProgress />)}
            {!loading && (
                <Box className="account-page" >
                    <Box id="outline-it" sx={(theme) => ({
                        display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', gap: 2,
                    })}>
                        <Typography level='h2'>Profile</Typography>
                        <Box sx = {{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pl: 5, pr: 5 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                gap: 2,
                                px: "1rem"
                            }}
                        >
                            <Box sx={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: {xs:'center', md: 'left'}
                            }}>
                                <Typography>
                                    Last updated at: {updatedAt}
                                </Typography>
                            </Box>
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                            >
                                <Typography>Name:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                                    <Input variant="outlined" disabled placeholder={name} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                    <Tooltip title={'Your name cannot be changed as your account is part of an organization.'}> 
                                        <Box>
                                            <HelpOutlineIcon/>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                            >
                                <Typography>Email:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                                    <Input variant="outlined" disabled placeholder={email} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                    <Tooltip title={'Your email annot be changed as your account is part of an organization.'}> 
                                        <Box>
                                            <HelpOutlineIcon/>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem"}}
                            >
                                <Typography>Type:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem", pl: '10px'}}>
                                    <Input variant="outlined" disabled placeholder={type} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx= {{
                            display: "flex", flexDirection: "column", backgroundColor: 'rgb(255, 255, 255, 50%)', gap: 2, pl: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: {xs:'center', md: 'left'}
                            }}>
                                <Typography>
                                    Created At: {createdAt}
                                </Typography>
                            </Box>
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                            >
                                <Typography>Job Title:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                                    <Input variant="outlined" disabled placeholder={jobTitle} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                </Box>
                            </Box>
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                            >
                                <Typography>Phone Number:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                                    <Input variant="outlined" disabled placeholder={phoneNumber} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                        <Box
                            sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-evenly'}}
                        >
                            <Button 
                                variant="plain"
                                onClick={() => {
                                    goChangePassword();
                                }} 
                                sx={{
                                    width: "30vw",
                                    fontWeight: "normal",
                                    minHeight: "2.5rem",
                                    backgroundColor: 'var(--light-grey)',
                                    color: 'var(--main-blue)',
                                }}
                            > 
                                CHANGE PASSWORD
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>    
    )
}

export default AccountView;