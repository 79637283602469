import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/joy';
import { styled } from '@mui/system';

const TimeStyledInput = styled('input')(({ theme }) => ({
    border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
    borderRadius: theme.radius.sm,
    minWidth: 0,
    width: '25px',
    height: '20px',
    padding: '10px',
    textAlign: 'center',
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    '&::placeholder': {
        opacity: 0.5,
        transition: '0.1s ease-out',
    },
    '&:focus': {
        outline: 'none',
        borderColor: theme.palette.primary.outlinedHoverBorder,
        boxShadow: `0 0 0 2px ${theme.palette.primary.outlinedHoverBorder}`,
    },
    '&:-webkit-autofill': {
        boxShadow: '0 0 0 1000px white inset',
    },
}));

const TimeInput = ({ label, labelMinWidth, onChange, defaultValue, disabled }) => {
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('00');

  useEffect(() => {
    if (defaultValue) {
      let timeString = defaultValue;
      if (defaultValue.includes('T')) {
        // Handle ISO 8601 format
        const timePart = defaultValue.split('T')[1];
        timeString = timePart ? timePart.split('.')[0] : '';
      } else if (defaultValue.includes(' ')) {
        const timePart = defaultValue.split(' ')[1];
        timeString = timePart ? timePart.split('.')[0] : '';
      }
      const [h = '', m = '', s = ''] = timeString.split(':');
      setHours(h.padStart(2, '0'));
      setMinutes(m.padStart(2, '0'));
      setSeconds(s.padStart(2, '0'));
    }
  }, [defaultValue]);

  const handleInputChange = (setter, index) => (e) => {
    let newValue = e.target.value.replace(/\D/g, '').slice(0, 2);
    
    // Validate input ranges
    if (index === 0 && parseInt(newValue) > 23) newValue = '23';
    if ((index === 1 || index === 2) && parseInt(newValue) > 59) newValue = '59';
    
    setter(newValue);
    
    const updatedValues = [hours, minutes, seconds];
    updatedValues[index] = newValue;
    
    const formattedTime = updatedValues.map(val => val.padStart(2, '0')).join(':');
    onChange(`2000-01-01 ${formattedTime}`);
  };

  return (
    <Box display='flex'>
      <Typography sx={{ minWidth: labelMinWidth, alignSelf: "center" }}>
          {label}: 
      </Typography>
      <Box
        pl='10px'
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          my: 1,
        }}
      >
        <TimeStyledInput
          placeholder="HH"
          disabled={disabled}
          value={hours}
          onChange={handleInputChange(setHours, 0)}
          maxLength={2}
        />
        <Typography level='h4'>:</Typography>
        <TimeStyledInput
          placeholder="MM"
          disabled={disabled}
          value={minutes}
          onChange={handleInputChange(setMinutes, 1)}
          maxLength={2}
        />
      </Box>
    </Box>
  );
};

export default TimeInput;