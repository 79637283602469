import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, Accordion, AccordionDetails, AccordionSummary, Typography, Divider, Button, Input } from "@mui/joy";
import { ArrowBack, PersonOutlined, Edit } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../helpers/AxiosConfiguration"; 
import AutoBuildFormSection from "../../components/AutomaticFormBuilder";
import RenderDifferentIcons from "../../components/AccordionIcons";
import FeedIcon from '@mui/icons-material/Feed';
import { convertJSONKeyToTitle } from "../../helpers/KeyConverters";
import { updateVisit } from "../../helpers/VisitRouter";
import { AlertContext } from "../../components/AlertContext";

const visitFormFields = {
    "field_order": ["date", "efrt_activation_time", "ambulance_number", "transport"],
    "date": {
        "type": "datetime",
        "display": "datetime_date",
        "required": true,
    },
    "efrt_activation_time": {
        "type": "datetime",
        "display": "datetime_hour",
        "required": true
    },
    "ambulance_number": {
        "type": "string",
        "required": true,
    },
    "transport": {
        "type": "string",
        "display": "dropdown",
        "values": ["Code 1 (Non-Transport)", "Code 1 (Immediate OMA)", "Code 1 (Security)", "Code 2 (McMaster Children's Hospital)", "Code 2 (Security to McMaster Children's Hospital)", "Code 3 (Ambulance)", "Other"],
        "required": true,
    }
}

const patientFormFields = {
    "field_order": ["first_name", "last_name", "sex", "date_of_birth", "address", "id_number"],
    "first_name": {
        "type": "string",
        "required": true,
    },
    "last_name": {
        "type": "string",
        "required": true,
    },
    "sex": {
        "type": "toggle",
        "values": ["male", "female", "other"]
    },
    "date_of_birth": {
        "type": "datetime",
        "display": "datetime_date",
        "required": true,
    },
    "address": {
        "type": "string",
        "required": true,
    },
    "id_number": {
        "type": "string",
        "required": true,
    },
};



export default function PastReportsEditingPage() {
    const { visitID } = useParams();
    const { addAlert } = useContext(AlertContext)
    const navigator = useNavigate();
    const [dirtyForm, setDirtyForm] = useState(false);
    const [visit, setVisit] = useState(null);
    const [formTop, setFormTop] = useState({});
    const [allFormField, setAllFormField] = useState(null);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [localTitle, setLocalTitle] = useState('');

    const fetchEverything = async () => {
        try {
            const fetchedVisit = await axiosInstance.get(`/api/v1/visits/${visitID}`);
            const fetchedFormFields = await axiosInstance.get('/api/v1/organization/details');

            setVisit(fetchedVisit.data);
            setFormTop({
                "date": fetchedVisit.data.details["incident_occurred_at"],
                "efrt_activation_time": fetchedVisit.data.details["efrt_activated_at"],
                "ambulance_number": fetchedVisit.data.details["ambulance_number"],
                "transport": fetchedVisit.data.details["transport"]
            })
            setAllFormField(fetchedFormFields.data);
        } catch(error) {
            console.log(error)
        }
    }

    const setFormTopInput = (value) => {
        setFormTop(value)
    }

    const setPatientFieldsInput = (values) => {
        setVisit((current) => ({
            ...current,
            'patient_info': values,
        }))
    }

    useEffect(() => {
        fetchEverything()
    }, [])

    const saveNewForm = async() => {
        try {
            const keysToKeep = ['call_info', 'treatment_info', 'vitals_info'];

            const newObject = {};

            keysToKeep.forEach((key) => {
                if (visit.hasOwnProperty(key)) {
                newObject[key] = visit[key]; // Directly copy the object reference
                }
            });

            await updateVisit(visitID, newObject, visit['patient_info'], formTop, localTitle);
            addAlert("Successfully saved!", "success");
        } catch (error) {
            console.log(error)
        }
    }

    const handleTitleChange = (event) => {
        setVisit(prev => ({ ...prev, title: event.target.value }));
        setLocalTitle(event.target.value)
        setDirtyForm(true);
    }

    const toggleTitleEdit = () => {
        setIsEditingTitle(!isEditingTitle);
    }

    return <Box sx={{height: '100vh', padding: 2.5}}>
        <Box>
            <IconButton onClick={() => {
                navigator(-1)
                }} sx={{ mr: 2 }}>
                <ArrowBack />
            </IconButton>
        </Box>
        {visit && allFormField && <Box sx={{height: "80vh", marginTop: '2vh', marginBottom: '1vh', overflowY: "auto"}}>
            <Box>
                {/* Date, EMS Activation, Ambulance #, Transport*/}
                {isEditingTitle ? (
                    <Input
                        value={(visit.title !== null && visit.title !== '' && visit.title !== 'null') ? visit.title : ''}
                        onChange={handleTitleChange}
                        onBlur={toggleTitleEdit}
                        endDecorator={<Edit />}
                    />
                ) : (
                    <Typography 
                        level='h2' 
                        onClick={toggleTitleEdit}
                        sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'action.hover' } }}
                    >
                        {(visit.title !== null && visit.title !== '' && visit.title !== 'null') ? visit.title : `Report ${new Date(visit.details.incident_occurred_at).toLocaleDateString()}, ${new Date(visit.details.efrt_activated_at).toLocaleTimeString()}`}
                        <IconButton onClick={toggleTitleEdit} size="small">
                            <Edit />
                        </IconButton>
                    </Typography>
                )}
                <Divider sx={{ my: 2 }} />
                <Box sx={{p: 2, border: "1px #c5cfd3 solid", borderRadius: "10px", overflow: 'auto'}} key={'visit_form'}>
                        <Typography level='h3' startDecorator={<FeedIcon fontSize="xl4"/>}> Report Information</Typography>
                        <Divider sx={{my: 1}}/>
                        <AutoBuildFormSection formFields={visitFormFields} retrieveInput={setFormTopInput} initialInput={formTop} setDirty={setDirtyForm} dirty={dirtyForm}/>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{p: 2, border: "1px #c5cfd3 solid", borderRadius: "10px", overflow: 'auto'}} key={'patient_form'}>
                    <Typography level='h3' startDecorator={<PersonOutlined fontSize="xl4"/>}> Patient Information</Typography>
                    <Divider sx={{my: 1}}/>
                    <AutoBuildFormSection formFields={patientFormFields} retrieveInput={setPatientFieldsInput} initialInput={visit['patient_info']} setDirty={setDirtyForm} dirty={dirtyForm}/>
                </Box>
                <Divider sx={{ my: 2 }} />
            </Box>
            <Box>
                <Box>
                    {Object.keys(allFormField['form_fields']).map( key => {
                        return <Box>
                                <Accordion square={false} variant="outlined" sx={{borderBottom: "1px #c5cfd3 solid", borderRadius: "10px"}} key={key}>
                                <AccordionSummary sx={{
                                    '.MuiAccordionSummary-button': {
                                        borderRadius: "10px"
                                    }}} >
                                    {/* Need logic to render an icon based off of the title of the section*/}
                                    <Box sx={{display: "flex", pl: 1.5, gap: 1, alignItems: "center"}}>
                                        <RenderDifferentIcons label={key}/>
                                        <h3>{convertJSONKeyToTitle(key)}</h3>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{overFlowX: "scroll",
                                    '.MuiAccordionDetails-content': {
                                        overflowX: 'auto'
                                    }
                                }}>
                                    <Divider sx={{mb: 1}}/>
                                    <AutoBuildFormSection formFields={allFormField['form_fields'][key]} initialInput={visit[key]} retrieveInput={(input) => {
                                        setVisit((currentFields) => ({
                                            ...currentFields,
                                            [key]: {
                                                ...currentFields[key],
                                                'user_edit': {
                                                    'value': input
                                                }
                                            },
                                        }));
                                    }} setDirty={setDirtyForm} dirty={dirtyForm}/>
                                </AccordionDetails>
                            </Accordion>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                    })}
                </Box>
            </Box>
        </Box>}
        <Box sx={{display: "flex", justifyContent: "end", gap: 1}}>
            <Button onClick={() => {
                saveNewForm()
                }}>Save</Button>
        </Box>
    </Box>
}