import React, { useEffect, useState } from "react";
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';

import { Box } from "@mui/joy";


// Depending on what the label contains, we're going to render different icons

function CallIcon() {
    return <AddIcCallOutlinedIcon fontSize="xl3"/>
}

function TreatmentIcon() {
    return <MedicalServicesOutlinedIcon fontSize="xl3" />
}

function VitalsIcon() {
    return <MonitorHeartOutlinedIcon fontSize="xl3" />
}

function DefaultIcon() {
    return <FormatAlignLeftOutlinedIcon fontSize="xl3" />
}

export default function RenderDifferentIcons(props) {
    const { label } = props;
    const [renderIcon, setRenderIcon] = useState(null)


    useEffect(() => {
        if (label.includes("treatment")) {
            setRenderIcon("treatment")
        } else if (label.includes("vital")) {
            setRenderIcon("vital")
        } else if (label.includes("call")) {
            setRenderIcon("call")
        } else {
            setRenderIcon("default")
        }
    }, [label])

    return (<Box>
        {renderIcon === "treatment" && <TreatmentIcon />}
        {renderIcon === "vital" && <VitalsIcon />}
        {renderIcon === "call" && <CallIcon />}
        {renderIcon === "default" && <DefaultIcon />}
    </Box>)

}