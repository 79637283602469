import React, { Component, useEffect } from "react";
import { AlertContext } from "../../components/AlertContext";
import { Box, List, ListItem, Typography, IconButton, Input, Skeleton, FormLabel, FormControl, Select, Option, Chip, Radio, Switch } from "@mui/joy";
import { axiosInstance } from "../../helpers/AxiosConfiguration";
import { calculateAndConvertTime, retrieveDateStringFromISO, retrieveTimeStringFromISO } from "../../helpers/TimeConversionHelper";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Search } from '@mui/icons-material';
import PastReportsExpanded from "../../components/PastReportsExpanded";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
  } from "react-router-dom";
import { getBackground, getColor } from "../../helpers/GetColors";
import { GetFeedbackIcon } from "../../helpers/IconGeneration";
import { getUserByID } from "../../helpers/UserAccounts";
import { mapStatusNames } from "../../components/FeedbackComponent";


class PastReports extends Component {
    static contextType = AlertContext;
    constructor(props) {
        super(props);
        this.state = {
            visitsGenerated: [],
            groupedVisits: {},
            dateRange: '3 months',
            page: 1,
            totalVisits: 0,
            size: 10,
            favorites: {},
            selectedVisit: null,
        };
        // Debounce search
        this.debounceFetch = null;

        this.setStatusToURL = this.setStatusToURL.bind(this);
        this.addSearchToURL = this.addSearchToURL.bind(this);
        this.addAssignedToURL = this.addAssignedToURL.bind(this);
    }

    toggleFavorite = (itemId) => {
        this.setState(prevState => ({
            favorites: {
                ...prevState.favorites,
                [itemId]: !prevState.favorites[itemId]
            }
        }));
    }

    componentDidMount() {
        this.fetchVisits(this.state.dateRange, this.state.page);
    }

    addSearchToURL(event) {
        const newSearchParams = event.target.value;

        if (this.debounceFetch) {
            clearTimeout(this.debounceFetch);
        }

        this.debounceFetch = setTimeout(() => {
            this.props.router.setSearchParams(params => {
                params.set("text", newSearchParams);
                return params;
            })
            this.fetchVisits(this.state.dateRange, this.state.page);
        }, 750);
    }

    addAssignedToURL(event) {
        if (this.debounceFetch) {
            clearTimeout(this.debounceFetch);
        }

        this.props.router.setSearchParams(params => {
            params.set("assigned_to_me", event.target.checked);
            return params;
        })
        this.fetchVisits(this.state.dateRange, this.state.page);
    }

    setStatusToURL(event, newValue) {
        if (this.debounceFetch) {
            clearTimeout(this.debounceFetch);
        }

        this.debounceFetch = setTimeout(() => {
            this.props.router.setSearchParams(params => {
                params.set("status", newValue);
                return params;
            })
            this.fetchVisits(this.state.dateRange, this.state.page);
        }, 750);
    }


    fetchVisits = async (timeRange, page) => {
        try {
            const response = await axiosInstance.get('/api/v1/visits', {
                params: {
                    'from_time': calculateAndConvertTime(timeRange),
                    'to_time': calculateAndConvertTime('current'),
                    'sort': '-created_at',
                    'assigned_to_me': this.props.router?.searchParams?.get("assigned_to_me") ?? false,
                    'page': page,
                    'size': this.state.size,
                    "status": this.props.router?.searchParams?.get("status") ?? 'draft',
                    "text": this.props.router?.searchParams?.get("text") ?? '',
                }
            });

            const { items, total, page: currentPage, size } = response.data;

            const addName = {}
            const visits = []
            for (const item of items) {
                if (item["user_account_id"] in addName) {
                    item["full_name"] = addName[item["user_account_id"]]
                } else {
                    try {
                        const response = await getUserByID(item["user_account_id"])

                        item["full_name"] = response.full_name;
                        addName[item["user_account_id"]] = response.full_name;
                    } catch (error) {
                        console.log(error)
                    }
                }
                visits.push(item);
            }

            const groupedVisits = this.groupVisitsByDate(visits);

            this.setState({
                visitsGenerated: visits,
                groupedVisits: groupedVisits,
                totalVisits: total,
                page: currentPage,
                size: size,
            });
        } catch (error) {
            this.context.addAlert("There was an error retrieving your visits", "danger");
        }
    }

    groupVisitsByDate(visits) {
        const grouped = {};
        visits.forEach(visit => {
            const date = new Date(`${visit.created_at}Z`);
            const header = this.getDateHeader(date);
            if (!grouped[header]) {
                grouped[header] = [];
            }
            grouped[header].push(visit);
        });
        return grouped;
    }

    getDateHeader(date) {
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        const lastWeek = new Date(now);
        lastWeek.setDate(now.getDate() - 7);
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        const lastThreeMonths = new Date(now);
        lastThreeMonths.setMonth(now.getMonth() - 3);


        if (date.getDate() === now.getDate()) {
            return "1. Today";
        } else if (date.getDate() === yesterday.getDate()) {
            return "2. Yesterday";
        } else if (date >= lastWeek) {
            return "3. Last Week";
        } else if (date >= lastMonth) {
            return "4. Last Month";
        } else if (date >= lastThreeMonths) {
            return "5. Last 3 Months";
        } else {
            return "6. Older";
        }
    }

    formatDate(dateString) {
        const date = new Date(`${dateString}`);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    }

    handlePageChange = (direction) => {
        const newPage = direction === 'next' ? this.state.page + 1 : this.state.page - 1;
        if (newPage > 0 && newPage <= Math.ceil(this.state.totalVisits / this.state.size)) {
            this.fetchVisits(this.state.dateRange, newPage);
        }
    }

    handleVisitClick = (visit) => {
        this.props.router.navigate(`/past-reports/${visit.id}`)
    }

    handleCloseVisit = () => {
        this.setState({ selectedVisit: null });
        this.fetchVisits(this.state.dateRange, this.state.page);
    }

    render() {
        const totalPages = Math.ceil(this.state.totalVisits / this.state.size);

        const sortedHeaders = Object.keys(this.state.groupedVisits).sort();

        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, gap: 2 }}>
                    <FormControl sx={{ flex: 1 }} size="sm">
                        <FormLabel>Search by title or patient</FormLabel>
                        <Input size="sm" placeholder="Search" startDecorator={<Search />} sx={{p: "8px"}} onChange={this.addSearchToURL} defaultValue={this.props.router.searchParams.get("text")}/>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel> Assigned to me </FormLabel>
                        <Switch size="lg" sx={{ my: 1 }} onChange={this.addAssignedToURL} checked={this.props.router.searchParams.get("assigned_to_me") === "true"}/>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>Status</FormLabel>
                        <Select
                        size="sm"
                        placeholder="Filter by status"
                        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                        defaultValue={this.props.router?.searchParams?.get("status") ?? 'draft'}
                        onChange={this.setStatusToURL}
                        >
                            <Option value="draft">Drafts</Option>
                            <Option value="prelim_check">Preliminary Check</Option>
                            <Option value="review">In Review</Option>
                            <Option value="denied">Denied</Option>
                            <Option value="approved">Approved</Option>
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>
                            {`${this.state.page}-${totalPages} of ${this.state.totalVisits} items`}
                        </FormLabel>
                        <Box sx={{display: "flex", padding: "2px"}}>
                            <IconButton 
                                onClick={() => this.handlePageChange('prev')} 
                                disabled={this.state.page === 1}
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <IconButton 
                                onClick={() => this.handlePageChange('next')} 
                                disabled={this.state.page === totalPages}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                    </FormControl>
                </Box>
                {this.state.visitsGenerated ? <Box>
                    {!this.state.selectedVisit ? (
                        <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                            {sortedHeaders.map((header) => (
                                <React.Fragment key={header}>
                                    <Box sx={{display: 'flex', p: 1, backgroundColor: "#EDF5FD"}}>
                                        <Typography fontWeight={'bold'}>{header.slice(3)}</Typography>
                                    </Box>
                                    {this.state.groupedVisits[header].map((item, index) => (
                                        <ListItem 
                                        key={item.id || index}
                                        sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            borderBottom: '1px solid #e0e0e0',
                                            py: 2,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => this.handleVisitClick(item)}
                                        >
                                            <Box sx={{ 
                                                marginLeft: "10%",
                                                textAlign: 'left',
                                                width: "30%",
                                            }}>
                                                <Typography noWrap>
                                                    {(item.title !== null && item.title !== '' && item.title !== 'null') ? item.title : `Report ${retrieveDateStringFromISO(item.details.incident_occurred_at)}, ${retrieveTimeStringFromISO(item.details.efrt_activated_at)}`}
                                                </Typography>
                                            </Box>
                                            <Chip variant="soft" sx={{backgroundColor: getBackground(item.status)}}
                                                    >
                                                        <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                                                            <GetFeedbackIcon status={item.status} />
                                                            <Typography color={getColor(item.status)}> {mapStatusNames(item.status)} </Typography>
                                                        </Box>
                                            </Chip>
                                            <Typography sx={{ml: "auto", width: "20%"}}>
                                                {item.full_name}
                                            </Typography>
                                            <Typography sx={{ ml: "auto", textAlign: 'left', width: "30%"}} noWrap>
                                                {this.formatDate(`${retrieveDateStringFromISO(item.details.incident_occurred_at)}T${retrieveTimeStringFromISO(item.details.efrt_activated_at)}`)}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </React.Fragment>
                            ))}
                            {Object.keys(this.state.groupedVisits).length === 0 && (
                                <ListItem>No visits to display</ListItem>
                            )}
                        </List>
                    ) : (
                        <Box sx={{ flexGrow: 1, overflow: 'none', width: '100%'}}>
                            <PastReportsExpanded 
                                visit={this.state.selectedVisit}
                                edit={this.props.sort === 'draft'}
                                onClose={() => this.setState({ selectedVisit: null })}
                            />
                        </Box>
                    )}
                </Box> : <Skeleton />}
            </Box>
        );
    }
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const [ searchParams, setSearchParams ] = useSearchParams();

        return (
          <Component
            {...props}
            router={{ location, navigate, params, searchParams, setSearchParams }}
          />
        );
      }
    
      return ComponentWithRouterProp;
}

export default withRouter(PastReports);