import { Box } from "@mui/joy";
import React from "react";


function UploadReport() {

    return <Box>
        Sample for now
    </Box>
}

export default UploadReport;