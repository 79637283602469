import React, { useEffect, useState, useRef } from 'react';
import '../../styling/LocationOfInjuries.css';
import { Box, Button, Typography } from '@mui/joy';


export const LocationOfInjuries = (props) => {
  const {initialValue, onChange, label, disabled} = props
  const [selectedParts, setSelectedParts] = useState(initialValue);
  const [isFrontView, setIsFrontView] = useState(true);
  const modified = useRef(false)

  const toggleBodyPart = (partName) => {
    setSelectedParts((prevParts) =>
      prevParts.includes(partName)
        ? prevParts.filter((part) => part !== partName)
        : [...prevParts, partName]
    );
  };

  const displayText = () => {
    let displayString = ""
    
    for (let index = 0; index < selectedParts.length; index++) {
      if (index === 0) {
        displayString += selectedParts[index];
      } else {
        displayString += ", " + selectedParts[index];
      }
    }
    return displayString
  }

  useEffect(() => {
    if (onChange && modified.current) {
      onChange(selectedParts);
    }
  }, [selectedParts])

  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    const partName = event.target.id;
    if (partName) {
      if (!modified.current) {
        modified.current = true;
      }
      toggleBodyPart(partName);
    }
  };

  const toggleView = () => {
    setIsFrontView(!isFrontView);
  };

  return (
    <div className="container">
      <div className="body-model">
        {label}:
        {isFrontView ? (
          <div className="front-view">
            <div
              id="Head"
              className={`body-part ${selectedParts.includes('Head') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="Neck"
              className={`body-part ${selectedParts.includes('Neck') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LeftChest"
              className={`body-part ${selectedParts.includes('LeftChest') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="RightChest"
              className={`body-part ${selectedParts.includes('RightChest') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="Stomach"
              className={`body-part ${selectedParts.includes('Stomach') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="LeftShoulder"
              className={`body-part ${selectedParts.includes('LeftShoulder') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="UpperLeftArm"
              className={`body-part ${selectedParts.includes('UpperLeftArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LeftElbow"
              className={`body-part ${selectedParts.includes('LeftElbow') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="LowerLeftArm"
              className={`body-part ${selectedParts.includes('LowerLeftArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="LeftHand"
              className={`body-part ${selectedParts.includes('LeftHand') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div 
              id="RightShoulder"
              className={`body-part ${selectedParts.includes('RightShoulder') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="UpperRightArm"
              className={`body-part ${selectedParts.includes('UpperRightArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="RightElbow"
              className={`body-part ${selectedParts.includes('RightElbow') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="LowerRightArm"
              className={`body-part ${selectedParts.includes('LowerRightArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="RightHand"
              className={`body-part ${selectedParts.includes('RightHand') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="UpperLeftLeg"
              className={`body-part ${selectedParts.includes('UpperLeftLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LeftKnee"
              className={`body-part ${selectedParts.includes('LeftKnee') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="LowerLeftLeg"
              className={`body-part ${selectedParts.includes('LowerLeftLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="LeftFoot"
              className={`body-part ${selectedParts.includes('LeftFoot') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="UpperRightLeg"
              className={`body-part ${selectedParts.includes('UpperRightLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="RightKnee"
              className={`body-part ${selectedParts.includes('RightKnee') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="LowerRightLeg"
              className={`body-part ${selectedParts.includes('LowerRightLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="RightFoot"
              className={`body-part ${selectedParts.includes('RightFoot') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
          </div>
        ) : (
          <div className="back-view">
            <div
              id="BackHead"
              className={`body-part ${selectedParts.includes('BackHead') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackNeck"
              className={`body-part ${selectedParts.includes('BackNeck') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LeftTrap"
              className={`body-part ${selectedParts.includes('LeftTrap') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="BackLeftShoulderBlade"
              className={`body-part ${selectedParts.includes('BackLeftShoulderBlade') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="RightTrap"
              className={`body-part ${selectedParts.includes('RightTrap') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="BackRightShoulderBlade"
              className={`body-part ${selectedParts.includes('BackRightShoulderBlade') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LowerBack"
              className={`body-part ${selectedParts.includes('LowerBack') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div 
              id="Tailbone"
              className={`body-part ${selectedParts.includes('Tailbone') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="BackUpperLeftArm"
              className={`body-part ${selectedParts.includes('BackUpperLeftArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerLeftArm"
              className={`body-part ${selectedParts.includes('BackLowerLeftArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackUpperRightArm"
              className={`body-part ${selectedParts.includes('BackUpperRightArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerRightArm"
              className={`body-part ${selectedParts.includes('BackLowerRightArm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackUpperLeftLeg"
              className={`body-part ${selectedParts.includes('BackUpperLeftLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerLeftLeg"
              className={`body-part ${selectedParts.includes('BackLowerLeftLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="LeftHeel"
              className={`body-part ${selectedParts.includes('LeftHeel') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
            <div
              id="BackUpperRightLeg"
              className={`body-part ${selectedParts.includes('BackUpperRightLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerRightLeg"
              className={`body-part ${selectedParts.includes('BackLowerRightLeg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div 
              id="RightHeel"
              className={`body-part ${selectedParts.includes('RightHeel') ? 'highlighted' : ''}`}
              onClick={handleClick}
            />
          </div>
        )}
      </div>
      <Box sx={{width:"200px", height: '400px'}}>
        <Button onClick={toggleView} sx={{height: "50px"}}>
          {isFrontView ? 'Posterior' : 'Anterior'}
        </Button>
        <Typography>
          {displayText(selectedParts)}
        </Typography>
      </Box>
    </div>
  );
};

