import React, { useEffect, useState } from 'react';
import { Box, IconButton, Option, Select, Typography } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

function SelectInputAutoForm(props) {
    // DONT USE value INSIDE THE SELECT. CHECK JOY UI DOCUMENTATION FOR MULTISELECT
    const { label, labelMinWidth, options, onChange, setEmpty, defaultValue, multiple, disabled } = props;

    return (
        <Box sx={{ display: 'flex'}}>
            <Typography sx={{ minWidth: labelMinWidth, alignSelf: "center", paddingRight: 1}}>
              {label}: 
            </Typography>
            <Select
              sx={{ minWidth: "30%"}}
              multiple={multiple}
              onChange={onChange}
              defaultValue={defaultValue}
              disabled={disabled}
            >
              {options.map((option, index) => {
                return (
                  <Option key={index} value={option}>
                      {option}
                  </Option>
              )})}
              <Option key="Empty" value="">
                N/A
              </Option>
            </Select>
            {!disabled && <IconButton onClick={setEmpty}>
              <CloseIcon />
            </IconButton>}
        </Box>
    );
}

export default SelectInputAutoForm;


