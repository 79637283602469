import React from 'react';
import { Box, Typography, Divider, Textarea } from '@mui/joy';

function TextAreaInputAutoForm(props) {
    const { label, height, ...rest } = props;

    return (
        <Box sx={{ width: '97%', borderColor: 'blue', border: '1px solid', borderRadius: '5px', height: height }}>
            <Box sx={{ px: '15px', py: '5px' }}>
                <Typography>
                    {label}: 
                </Typography>
            </Box>
            <Box sx={{ px: '15px' }}>
                <Divider />
            </Box>
            <Textarea 
                minRows={8} 
                sx={{
                    border: 'none',
                    '&::before': {
                        content: 'none',
                    },
                    '&:focus-within': {
                        outline: 'none',
                        boxShadow: 'none',
                    },
                    height: "75%"
                }}
                {...rest}
                
            />
        </Box>
    );
}

export default TextAreaInputAutoForm;