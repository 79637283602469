import React, { useState, useEffect } from 'react';
import { Box, IconButton, Input, Sheet, Typography, Divider } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';

import { isIsoDate } from '../../helpers/TimeConversionHelper';

export default function VitalsTableInput({ onChange, label, value, disabled }) {
    const [columns] = useState([
        { key: 'vitals_time', name: 'Time', colspan: 1, width: '8%', minWidth: '20px' },
        // [0]: vitals_spo2_p, [1]: vitals_spo2_ra
        { key: 'vitals_spo2', name: 'SpO2', colspan: 2, width: '14%' },
        { key: 'vitals_temp', name: 'Temp', colspan: 1, width: '8%' },
        { key: 'vitals_bgl', name: 'BGL', colspan: 1, width: '8%' },
        { key: 'vitals_resp', name: 'Resp', colspan: 1, width: '10%' },
        { key: 'vitals_pulse', name: 'Manual Pulse', colspan: 1, width: '12%' },
        // [0]: vitals_bp_d, [1]: vitals_bp_s
        { key: 'vitals_bp', name: 'BP', colspan: 2, width: '14%' },
        { key: 'vitals_pupils', name: 'Pupils', colspan: 1, width: '8%' },
        { key: 'vitals_skin', name: 'Skin', colspan: 1, width: '8%' }
    ]);

    const createEmptyRow = () => {
        return columns.reduce((acc, col) => {
            acc[col.key] = col.colspan === 1 ? '' : ['', ''];
            return acc;
        }, {});
    };

    const [rows, setRows] = useState([createEmptyRow()]);

    useEffect(() => {
        if (value && Array.isArray(value) && value[0] && (typeof value[0] === 'object')) {
            setRows(value);
        } else {
            setRows([createEmptyRow()]);
        }
    }, [value, columns]);

    const handleInputChange = (rowIndex, header, inputValue, subIndex = null) => {
        const newRows = rows.map((row, index) => {
            if (index === rowIndex) {
                if (subIndex !== null) {
                    const newValue = [...row[header]];
                    newValue[subIndex] = inputValue;
                    return { ...row, [header]: newValue };
                } else {
                    return { ...row, [header]: inputValue };
                }
            }
            return row;
        });
        setRows(newRows);
        updateParent(newRows);
    };

    const addRow = () => {
        const newRows = [...rows, createEmptyRow()];
        setRows(newRows);
        updateParent(newRows);
    };

    const removeRow = () => {
        if (rows.length > 1) {
            const newRows = rows.slice(0, -1);
            setRows(newRows);
            updateParent(newRows);
        }
    };

    const updateParent = (newRows) => {
        onChange(newRows);
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Typography level="body-lg" sx={{ display: 'flex', justifyContent: 'center' }}>{label || 'Vitals'}</Typography>
            <Sheet variant="outlined">
                <table style={{ width: '100%', minWidth: "800px", tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                    <colgroup>
                        {columns.flatMap(col => 
                            Array(col.colspan).fill().map(() => <col style={{width: col.width}} />)
                        )}
                    </colgroup>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index} colSpan={col.colspan} style={{ 
                                    padding: '8px', 
                                    borderBottom: '1px solid #ccc', 
                                    borderRight: '1px solid #ccc', 
                                    textAlign: 'center',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    <Typography level="body-sm" fontSize='16px' color='#32383E'>{col.name}</Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => {
                            return (
                            <tr key={rowIndex}>
                                {columns.map((col, colIndex) => {
                                    if (col.key.includes('time')) {
                                        if(isIsoDate(row[col.key])) {
                                            row[col.key] = row[col.key].split("T")[1].split(".")[0]
                                        }
                                    }
                                    if (col.colspan !== 1 && !Array.isArray(row[col.key])) {
                                        row[col.key] = [row[col.key], ""]
                                    }
                                    return(
                                    <td key={colIndex} colSpan={col.colspan} style={{ 
                                        borderBottom: '1px solid #ccc', 
                                        borderRight: '1px solid #ccc',
                                        height: '20px'
                                    }}>
                                        {col.colspan === 1 ? (
                                            <Input
                                                value={row[col.key]}
                                                disabled={disabled}
                                                onChange={(e) => handleInputChange(rowIndex, col.key, e.target.value)}
                                                size="sm"
                                                variant="plain"
                                                sx={{
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    width: '100%',
                                                }}
                                            />
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                <Input
                                                    value={row[col.key][0]}
                                                    disabled={disabled}
                                                    onChange={(e) => handleInputChange(rowIndex, col.key, e.target.value, 0)}
                                                    size="sm"
                                                    variant="plain"
                                                    sx={{
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        width: '50%',
                                                    }}
                                                />
                                                <Divider orientation="vertical" sx={{ height: '100%', mx: 0.5 }} />
                                                <Input
                                                    value={row[col.key][1]}
                                                    disabled={disabled}
                                                    onChange={(e) => handleInputChange(rowIndex, col.key, e.target.value, 1)}
                                                    size="sm"
                                                    variant="plain"
                                                    sx={{
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        width: '50%',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </td>
                                )})}
                            </tr>
                        )})}
                    </tbody>
                </table>
            </Sheet>
            <Box display='flex' justifyContent='center' gap={2}>
                <IconButton onClick={addRow} variant="outlined" sx={{ mt: 2 }} disabled={disabled}>
                    <Add />
                </IconButton>
                <IconButton 
                    onClick={removeRow} 
                    variant="outlined" 
                    sx={{ mt: 2 }}
                    disabled={rows.length === 1}
                >
                    <Remove />
                </IconButton>
            </Box>
        </Box>
    );
}