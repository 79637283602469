import { createSlice } from "@reduxjs/toolkit";


export const saveFieldSlice = createSlice({
    name: "saveField",
    initialState: {
        value: false,
    },
    reducers:{
        toggleTrue: (state) => {
            state.value = true;
        },
        toggleFalse: (state) => {
            state.value = false;
        },
    }
})


export const { toggleTrue, toggleFalse } = saveFieldSlice.actions;

export default saveFieldSlice.reducer;