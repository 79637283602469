import { useState, useEffect } from "react";
import { getUserID } from "./AuthBarrier";
import { axiosInstance } from "./AxiosConfiguration";


export function useFetchVisitExecutives(visitID, reload) {
    const [executives, setExecutives] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const fetchExecutives = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/api/v1/visits/${visitID}/executives`);
            setExecutives(response.data);
            setError(null);
        } catch(error) {
            setError(error);
            setExecutives(null); 
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchExecutives();
    }, [visitID, reload]);

    return { executives, loading, error }
}

export async function tagExecutiveToVisit(visitID, user_account_id) {
    try {
        const response = await axiosInstance.post(`/api/v1/visits/${visitID}/executives`, {
            "user_account_id": user_account_id,
        })

        return response.status
    } catch(error) {
        console.log(error)
    }
}

export async function removeTaggedExecutive(visitID, executive_id) {
    try {
        const response = await axiosInstance.delete(`/api/v1/visits/${visitID}/executives/${executive_id}`)

        return response.status
    } catch(error) {
        console.log(error)
    }
}

export async function getCurrentVisitExecutive(visitID) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visitID}/executives`);
        
        for (const responder of response.data) {
            if (responder['user_account_id'] === getUserID()) {
                return responder;
            }
        }

        throw new Error("Couldn't find your responder record");
    } catch(error) {
        console.log(error)
    }
}