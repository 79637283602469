import React, { useEffect, useState } from "react";
import Intercom from '@intercom/messenger-js-sdk';
import { getClientUser } from "../helpers/UserAccounts";

function Component({ user_id, user_name, user_email, user_created_at }) {
    useEffect(() => {
        // Initialize Intercom only once on mount
        Intercom({
            app_id: 'x7wcjiar',
            user_id: user_id,
            name: user_name,
            email: user_email,
            created_at: user_created_at,
        });

        // Cleanup function if Intercom has a close or reset option
        return () => {
            if (Intercom.shutdown) {
                Intercom.shutdown(); // Example cleanup if supported
            }
        };
    }, [user_id, user_name, user_email, user_created_at]);

    return null; // No content to display
}

export default function GetHelp() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getNowUser = async () => {
            const response = await getClientUser();
            setUser(response);
        };
        
        getNowUser();
    }, []);

    return (
        <>
            {user && (
                <div> {/* Example styling */}
                    <Component
                        user_id={user['id']}
                        user_name={user['full_name']}
                        user_email={user['email']}
                        user_created_at={user['created_at']}
                    />
                </div>
            )}
        </>
    );
}
