import React, { useEffect, useState } from 'react';

const AudioLevelIcon = ({ audio }) => {
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    let audioContext;
    let analyser;
    let dataArray;
    let source;
    let rafId;

    // Set up the audio context and analyser
    const setupAudio = async () => {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      dataArray = new Uint8Array(analyser.frequencyBinCount);
      source = audioContext.createMediaStreamSource(audio);
      source.connect(analyser);

      // Function to update the audio level
      const updateAudioLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const avg = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
        setAudioLevel(avg / 255); // Normalize to 0-1 range

        rafId = requestAnimationFrame(updateAudioLevel); // Keep updating
      };

      updateAudioLevel();
    };

    setupAudio();

    // Cleanup on unmount
    return () => {
      cancelAnimationFrame(rafId);
      analyser.disconnect();
      source.disconnect();
      audioContext.close();
    };
  }, [audio]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: audioLevel > 0.05 ? 'var(--main-blue)' : 'white',
          height: '3px',
          width: '5px',
          margin: '2px',
        }}
      />
      <div
        style={{
          backgroundColor: audioLevel > 0.1 ? 'var(--main-blue)' : 'white',
          height: '6px',
          width: '5px',
          margin: '2px',
        }}
      />
      <div
        style={{
          backgroundColor: audioLevel > 0.2 ? 'var(--main-blue)' : 'white',
          height: '9px',
          width: '5px',
          margin: '2px',
        }}
      />
      <div
        style={{
          backgroundColor: audioLevel > 0.3 ? 'var(--main-blue)' : 'white',
          height: '12px',
          width: '5px',
          margin: '2px',
        }}
      />
      <div
        style={{
          backgroundColor: audioLevel > 0.5 ? 'var(--main-blue)' : 'white',
          height: '15px',
          width: '5px',
          margin: '2px',
        }}
      />
    </div>
  );
};

export default AudioLevelIcon;