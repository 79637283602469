import { DialogContent, DialogTitle, Modal, ModalDialog, Table, Box, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { convertTime } from "../helpers/TimeConversionHelper";
import { GetFeedbackIcon } from "../helpers/IconGeneration";
import { getBackground, getColor } from "../helpers/GetColors";
import { getUserByID } from "../helpers/UserAccounts";

function mapStatusNames(status) {
    switch(status) {
        case "prelim_check": {
            return "Preliminary Check"
        }
        case "prelim_resp_exec_pending": {
            return "Requested changes"
        }
        case "prelim_resp_exec_approved": {
            return "Approved"
        }
        default: {
            return status.charAt(0).toUpperCase() + status.slice(1)
        }
    }
}

function StatusesModal({open, setClose, statuses}) {
    // Map user ID to full name
    const allUsers = {}
    const [statusesState, setStatusesState] = useState(null);

    const fetchUser = async (user_account_id) => {
        if (user_account_id in allUsers) {
            return allUsers[user_account_id]
        } else {
            try {
                const response = await getUserByID(user_account_id);

                allUsers[user_account_id] = response.full_name
                return response.full_name;
            } catch (error) {
                console.log(error)
                return null;
            }
        }
    }

    const setupAllUsers = async () => {
        const allData = []
        for (let status of statuses) {
            status['full_name'] = await fetchUser(status['user_id'])
            allData.push(status);
        }

        setStatusesState(allData);
    }

    useEffect(() => {
        setupAllUsers();
    }, [statuses])

    return <Modal open={open} onClose={() => setClose()}>
        <ModalDialog>
            <DialogTitle> Previous Report Statuses</DialogTitle>
            <DialogContent>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: "25%"}}>
                                Status:
                            </th>
                            <th style={{ width: "20%"}}>
                                Date Posted:
                            </th>
                            <th style={{ width: "35%"}}>
                                Comment:
                            </th>
                            <th style={{ width: "20%"}}>
                                By:
                            </th>
                        </tr>
                    </thead>
                    {statusesState && <tbody>
                        {statusesState.map((status, index) => {
                            return <tr>
                                <td>
                                    <Box sx={{display: "flex", width: "fit-content", p: 1, backgroundColor: getBackground(status["value"]), borderRadius: "8px", alignItems: "center"}}>
                                        <GetFeedbackIcon status={status["value"]} />
                                        <Typography color={getColor(status['value'])}> {mapStatusNames(status['value'])} </Typography>
                                    </Box>
                                </td>
                                <td>
                                    {convertTime(status["created_at"])}
                                </td>
                                <td>
                                    {status["comment"]}
                                </td>
                                <td>
                                    {status["full_name"]}
                                </td>
                            </tr>
                        })}
                    </tbody>}
                </Table>
            </DialogContent>
        </ModalDialog>
    </Modal>
}

export default StatusesModal ;