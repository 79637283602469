import { getPresignedURL, putAudioIntoPresigned } from "./AWSHelpers";
import { axiosInstance } from "./AxiosConfiguration";




export async function getVisitAttachments(visitID) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visitID}/attachments`);

        return response.data
    } catch(error) {
        console.log(error);
    }
}

export async function getVisitAttachmentFile(visitID, attachmentID) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visitID}/attachments/${attachmentID}`);

        return response.data
    } catch(error) {
        console.log(error);
    }
}

export async function uploadFileToVisit(visitID, file, fileName) {
    try {
        const presignedURL = await getPresignedURL();
        await putAudioIntoPresigned(presignedURL, file);

        const response = await axiosInstance.post(`/api/v1/visits/${visitID}/attachments`, {
            "file_name": fileName,
            "file_uri": presignedURL.fields.key
        });

    } catch (error) {
        console.log(error);
        throw Error;
    }
}

export async function deleteAttachmentFromVisit(visitID, attachmentID) {
    try {
        const response = await axiosInstance.delete(`/api/v1/visits/${visitID}/attachments/${attachmentID}`);

        return response;
    } catch(error) {
        console.log(error);
    }
}