
import { Box, Checkbox, Typography } from "@mui/joy";
import { useEffect, useState } from "react";


function CheckBoxAutoForm(props) {
    const { defaultValue, onChange, labelMinWidth, label, disabled } = props;
    const [ checked, setChecked ] = useState(false);

    const handleChanged = (event) => {
        onChange(event.target.checked);
        setChecked(event.target.checked);
    }

    useEffect(() => {
        if (defaultValue) {
            setChecked(defaultValue)
        }
    }, [defaultValue])



    return <Box sx={{display: "flex"}}>
        <Typography sx={{minWidth: labelMinWidth, alignSelf: "center", paddingRight: 1}}>
            {label}: 
        </Typography>
        <Checkbox 
            checked={checked}
            onChange={handleChanged}
            disabled={disabled}
        />
    </Box>
}

export default CheckBoxAutoForm;