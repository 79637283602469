import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const GetFeedbackIcon = ({status}) => {
    return status === "review" ? <AccessTimeIcon /> : 
        status === "approved" ? <CheckCircleOutlineIcon /> :
        status === "denied" ? <ErrorOutlineIcon /> :
        <ErrorOutlineIcon />
}

